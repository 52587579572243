import React from "react";
import Helmet from "react-helmet";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { startCase } from "lodash";
import styled from "styled-components";

import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import useSiteMetadata from "../components/SiteMetadata";
import SlideShow from "../components/SlideShow";
import SocialLinksSmall from "../components/SocialLinksSmall";

const StandardPageStyles = styled.section`
  .title-wrapper {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;

    h2 {
      margin-bottom: 0;
    }
    .social-links {
      padding-top: 0;
    }
  }
`;

export const StandardPageTemplate = ({
  title,
  content,
  contentComponent,
  gallery
}) => {
  const PageContent = contentComponent || Content;
  const slideImages = gallery
    ? gallery.images.map(image => {
      return {
        src: image.image.childImageSharp.full,
        title: image.title
      }
    })
    : null;

  return (
    <StandardPageStyles className="section section--gradient">
      <div className="columns">
        <div className="column is-12">
          <div className="title-wrapper">
            <h2 className="title has-text-weight-bold is-bold-light">
              {title}
            </h2>
            <SocialLinksSmall></SocialLinksSmall>
          </div>

          {/* <Gallery title="" slug="" images={images} itemsPerRow={[3]}></Gallery> */}
          {slideImages && <SlideShow slideImages={slideImages}></SlideShow>}
          <PageContent className="content" content={content} />
        </div>
      </div>
    </StandardPageStyles>
  );
};

StandardPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
  gallery: PropTypes.any
};

const StandardPage = ({ data }) => {
  const { markdownRemark: post } = data;
  const { title, gallery } = post.frontmatter;
  const siteTitle = useSiteMetadata().title;

  return (
    <Layout>
      <Helmet>
        <title>{`${siteTitle} | ${title}`}</title>
      </Helmet>
      <StandardPageTemplate
        contentComponent={HTMLContent}
        title={startCase(title)}
        content={post.html}
        gallery={gallery}
      />
    </Layout>
  );
};

StandardPage.propTypes = {
  data: PropTypes.object.isRequired
};

export default StandardPage;

export const StandardPageQuery = graphql`
  query StandardPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        gallery {
          galleryTitle
          images {
            title
            image {
              childImageSharp {
                thumb: gatsbyImageData(
                  width: 300
                  height: 300
                  placeholder: BLURRED
                )
                full: gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  }
`;
