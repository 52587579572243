import React from "react";
// import { Slide } from "react-slideshow-image";
// import 'react-slideshow-image/dist/styles.css'
import { GatsbyImage } from 'gatsby-plugin-image'
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import styled from "styled-components";

const SlideContainerStyles = styled.div`
  width: 70%;
  @media screen and (max-width: 768px) {
    width: 90%;
  }
  margin: auto auto 20px;
  h3 {
    text-align: center;
  }
  .slick-next:before {
    color: black;
  }
  .slick-prev:before {
    color: black;
  }
  margin: 30px auto;
  .each-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    max-height: 400px;
    position: relative;
    margin-bottom: 30px;
    .gatsby-image-wrapper {
      max-height: 100%;
    }
    span {
      font-size: 1rem;
      line-height: 2rem;
      background: #2d2d2d;
      color: #efefef;
      text-align: left;
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      padding: 0 10px;
    }
  }
  @media screen and (max-width: 768px) {
    .each-slide {
      max-height: 300px;
    }
  }
`;

const SlideShow = ({ slideImages }) => {

  const settings = {
    dots: true,
    infinite: true,
    autoplay: false,
    adaptiveHeight: true
  }

  return (
    <SlideContainerStyles className="slide-container">
      {typeof window !== 'undefined' && (
        <Slider {...settings} className="overflow-hidden">
          {slideImages.map((slideImage, i) => (
            <div className="each-slide">
              <GatsbyImage image={slideImage.src} />
              <span className="slide-title">{slideImage.title}</span>
            </div>
          ))}

        </Slider>
      )}
    </SlideContainerStyles>
  );
};

export default SlideShow;
